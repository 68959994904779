import * as THREE from 'three';

import ThreeGlobe from "three-globe";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import countries from "./files/custom.geo.json";
import lines from "./files/lines.json";
import map from "./files/map.json";


var renderer, camera, scene, controls;
const globeCanvas = document.getElementById("globeSection")
var Globe;
document.addEventListener('DOMContentLoaded', () => {
init();
initGlobe();
onWindowResize();
animate();
})

function init() {

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(globeCanvas.clientWidth, globeCanvas.clientHeight);

  globeCanvas.appendChild(renderer.domElement);

  scene = new THREE.Scene();

  var ambientLight = new THREE.AmbientLight(0xbbbbbb, 0.3)
  scene.add(ambientLight)
  scene.background = new THREE.Color(0xffffff);

  camera = new THREE.PerspectiveCamera();

  camera.aspect = globeCanvas.clientWidth / globeCanvas.clientHeight;

  camera.updateProjectionMatrix();

  var dLight = new THREE.DirectionalLight(0xffffff, 0.8);
  dLight.position.set(-800, 2000, 400);
  camera.add(dLight);

  var dLight1 = new THREE.DirectionalLight(0x7982f6, 1);
  dLight1.position.set(-200, 500, 200);
  camera.add(dLight1);

  var dLight2 = new THREE.PointLight(0x8566cc, 0.5);
  dLight2.position.set(-200, 500, 200);
  camera.add(dLight2);

  camera.position.z = 400;
  camera.position.x = 400;
  camera.position.y = 300;

  scene.add(camera);

  scene.fog = new THREE.Fog(0x535ef3, 400, 2000);

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dynamicDampingFactor = 0.01;
  controls.enablePan = false;
  controls.minDistance = 240;
  controls.maxDistance = 240;
  controls.rotateSpeed = 0.8;
  controls.autoRotate = true;
  controls.autoRotateSpeed = -1.3;

  window.addEventListener("resize", onWindowResize, false);
}

function initGlobe() {

  Globe = new ThreeGlobe({
    waitForGlobeReady: true,
    animateIn: true,
  })

  .hexPolygonsData(countries.features)
  .hexPolygonResolution(3)
  .hexPolygonMargin(0.4)
  .showAtmosphere(true)
  .atmosphereColor("#EAE9F6")
  .atmosphereAltitude(0.25)
  

  setTimeout(() => {
    Globe.arcsData(lines.pulls)
    .arcColor((e) => {
      return e.status ? "#EE6760" : "#E38743";
    })
    .arcAltitude((e) =>{
      return e.arcAlt;
    })
    .arcStroke((e) => {
      return e.status ? 0.5 : 0.3
    })
    .arcDashLength(0.9)
    .arcDashGap(4)
    .arcDashAnimateTime(1000)
    .arcsTransitionDuration(1000)
    .arcDashInitialGap((e) => e.order * 1)
  }, 1000)

  Globe.rotateX(Math.PI* 1/6)
  Globe.rotateY(Math.PI * 1/4);
  Globe.rotateZ(Math.PI * -1/6);
  const globeMaterial = Globe.globeMaterial();
  globeMaterial.color = new THREE.Color(0x3a228a);
  globeMaterial.emissive = new THREE.Color(0x220038);
  globeMaterial.emissiveIntensity = 0.1;
  globeMaterial.shininess = 0.7;
  scene.add(Globe);
}

function onWindowResize() {
  renderer.setSize(globeCanvas.clientWidth, globeCanvas.clientHeight);
  camera.aspect = globeCanvas.clientWidth / globeCanvas.clientHeight;
  camera.updateProjectionMatrix();
}

function animate() {
  camera.lookAt(scene.position);
  controls.update();
  renderer.render(scene, camera);
  requestAnimationFrame(animate);
}
